<template>
  <div class="page-wrapper">

    <div data-w-id="168641a1-4189-3d35-88f0-3cb624138e4b" data-animation="default" data-collapse="medium" data-duration="500" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
      <div class="navbar-inner">
        <a href="index.html" aria-current="page" class="logo-wrapper w-nav-brand w--current"><img src="./assets/images/62a7a9ec7d67d110be7b8907_ERNIE-1.png" loading="lazy" alt="Ernie the Cock" class="image"></a>
        <nav role="navigation" class="nav-menu w-nav-menu">
          <div data-w-id="168641a1-4189-3d35-88f0-3cb624138e50" class="link-wrapper">
            <a href="https://portchie-nft.com/#collection" class="nav-link w-nav-link">Collection</a><img src="./assets/images/62b0cbbf67a17b1330a5afc3_Anthea_Megalo_Pink-1-1-1.png" loading="lazy" alt="a pink daisy from Cycling by the Riverside NFT" class="link-blob">
          </div>
          <div data-w-id="168641a1-4189-3d35-88f0-3cb624138e53" class="link-wrapper">
            <a href="https://portchie-nft.com/#content" class="nav-link w-nav-link">Rarity</a><img src="./assets/images/62b0cbbf67a17b1330a5afc3_Anthea_Megalo_Pink-1-1-1.png" loading="lazy" alt="a pink daisy from Cycling by the Riverside NFT" class="link-blob">
          </div>
          <div data-w-id="168641a1-4189-3d35-88f0-3cb624138e56" class="link-wrapper">
            <a href="https://portchie-nft.com/#roadmap" class="nav-link w-nav-link">Roadmap</a><img src="./assets/images/62b0cbbf67a17b1330a5afc3_Anthea_Megalo_Pink-1-1-1.png" loading="lazy" alt="a pink daisy from Cycling by the Riverside NFT" class="link-blob">
          </div>
          <div class="link-wrapper">
            <a href="https://portchie-nft.com/#team" class="nav-link w-nav-link">Story</a><img src="./assets/images/62b0cbbf67a17b1330a5afc3_Anthea_Megalo_Pink-1-1-1.png" loading="lazy" alt="a pink daisy from Cycling by the Riverside NFT" class="link-blob">
          </div>
          <div class="link-wrapper">
            <a href="howto.html" class="nav-link w-nav-link">How to</a><img src="./assets/images/62b0cbbf67a17b1330a5afc3_Anthea_Megalo_Pink-1-1-1.png" loading="lazy" alt="a pink daisy from Cycling by the Riverside NFT" class="link-blob">
          </div>
          <div data-w-id="168641a1-4189-3d35-88f0-3cb624138e5c" class="navbar-button-wrapper">
            <div class="navbar-button-divider"></div>
            <a href="https://discord.gg/DfvrqApVDC" target="_blank" class="primary-button w-button">Join Discord</a>
          </div>
        </nav>
        <div class="menu-button w-nav-button"><img src="https://uploads-ssl.webflow.com/629518601e35e3a732d94362/629518601e35e30276d9437a_menu_open_icon.svg" loading="lazy" alt="" class="open-menu-icon"><img src="https://uploads-ssl.webflow.com/629518601e35e3a732d94362/629518601e35e34d34d9437b_menu_close_icon.svg" loading="lazy" alt="" class="close-menu-icon"></div>
      </div>
      <div class="navbar-white-overlay"></div>
    </div>


    <div id="mint" class="section collection-section wf-section">
      <div class="wide-container center">
        <div class="section-title-container for-collection">
          <div class="section-name">
            <div class="section-name-text">Be part of history</div>
          </div>
          <h2 class="heading-2">Mint your NFT</h2>
        </div>
        <div class="collection-main">
          <div class="collection-content">
            <div class="collection-info-container"></div>
          </div>
        </div>
        <div class="columns-2 w-row">
          <div class="w-col w-col-4">
            <a v-on:click="decr" href="#" class="w-inline-block boxhead">
              <div class="mint-amount">&lt;</div>
            </a>
          </div>
          <div class="w-col w-col-4">
            <div class="mint-amount"> {{ numToMint }}</div>
          </div>
          <div class="w-col w-col-4">
            <a v-on:click="inc" href="#" class="w-inline-block boxhead">
              <div class="mint-amount">&gt;</div>
            </a>
          </div>
        </div>

          <div v-if="metamaskDetected">
            <div v-if="metamaskConnected()">
              <div v-if="readyToMint()">
                <div v-if="mintInProgress">
                 <a class="connect-wallet w-button">Minting..</a>
                </div>
              <div v-else>
                <a v-on:click="mint" href="#" class="connect-wallet w-button boxhead">Mint</a>
              </div>
              </div>
              <div v-else>
                <p class="paragraph-copy">Mint is not running or your address is not whitelisted</p>
              </div>

            </div>
            <div v-else>
              <a v-on:click="enableMetaMask" href="#" class="connect-wallet w-button boxhead">Connect MetaMask</a>
            </div>
           
            <!-- <a v-else v-on:click="enableMetaMask" class="connect-wallet w-button">Connect MetaMask</a> -->
          </div>
          <div v-else>Please install and configure MetaMask</div>
          <div v-if="transactionFeedback">
            <div v-if="transactionSuccessful">
              <p class="paragraph-copy"><a :href=transactionStatus() target="_blank">View transaction</a></p>
            </div>
            <div v-else>
              <p v-if="invalidBalance" class="paragraph-copy">Transaction unsuccesful: invalid balance</p>
              <p v-else class="paragraph-copy">Transaction unsuccesful</p>
            </div>
        </div>
        <div v-else>
        </div>
        <p class="paragraph-copy">Total mint price: {{ getPrice() }} ETH<br>Maximum mints per transaction: 20</p>
        <p class="paragraph-copy" v-if="metamaskConnected()">Total Supply: {{ totalSupply}}</p>
        <p class="paragraph-copy"> <strong>Need help?</strong><br>Join our <a href="https://discord.gg/DfvrqApVDC" target="_blank">Discord</a> server and open a support ticket.<strong></strong></p>
      
        <p id="w-node-e859dd73-4a50-e3ea-9976-2c9f68c48540-303b5bfd" class="bottomemailer copyright-text">
          <a href="https://portchie-nft.com/terms-and-conditions.html">Terms and Conditions</a><strong><br></strong>
        </p>
      
      </div>
    </div>
    </div>
</template>

<script>

const Web3 = require('web3')
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import portchieContract from './Portchie.json'

// VUE_APP_LOG_PRODUCTION_TIP=true
// VUE_APP_CONTRACT_ADDR=0xABe296C4Ec15B3Fc4B150041731E44298004104a
// VUE_APP_CONTRACT_CODE_URL=https://etherscan.io/address/0xABe296C4Ec15B3Fc4B150041731E44298004104a#code
// VUE_APP_OPESEA_COLLECTION_URL=https://opensea.io/collection/cryptocheeks-your-favourite-crypto-asset
// VUE_APP_OPESEA_ASSET_URL=https://opensea.io/assets/0xABe296C4Ec15B3Fc4B150041731E44298004104a/
// VUE_APP_ALCHEMY_URL=wss://eth-rinkeby.alchemyapi.io/v2/oxJpvj0-nqr5BvAw1dqMiRBKwXhRBEv4
// VUE_APP_ETH_NETWORK=rinkeby
// VUE_APP_ASSET_FOLDER=staging
// VUE_APP_ASSET_BEFORE_GO_LIVE=false

const alcgemyURL = "wss://eth-mainnet.g.alchemy.com/v2/jSTqowga7F4FuP_VGcKk0pGU3yyd6t2F"
const contractAddress = "0x59FAd83d35b71409bfB4Ba24b4bF7C78c25C519c"
const ethNetwork = "main"

// const alcgemyURL = process.env.VUE_APP_ALCHEMY_URL
// const contractAddress = process.env.VUE_APP_CONTRACT_ADDR
// const contractCodeURL = process.env.VUE_APP_CONTRACT_CODE_URL
// const openSeaCollectionURL = process.env.VUE_APP_OPESEA_COLLECTION_URL
// const openSeaAssetURL = process.env.VUE_APP_OPESEA_ASSET_URL
// const ethNetwork = process.env.VUE_APP_ETH_NETWORK
// const assetFolder = process.env.VUE_APP_ASSET_FOLDER
// const beforeGolive = process.env.VUE_APP_ASSET_BEFORE_GO_LIVE === "true"

console.log(alcgemyURL)
const web3 = new createAlchemyWeb3(
  alcgemyURL,
);

export default {
  name: 'App',
  mounted() {
    if (window.ethereum) {
      console.log("MetaMask detected")
      this.metamaskDetected = true
    }
  },
  data() {
    return {
      totalSupply: 0,
      price: 0.1,
      metamaskDetected: false,
      metaMaskConnected: false,
      account: "",
      numToMint: 1,
      transactionId: "",
      transactionFeedback: false,
      priceWei: 0,
      isMintRunning: false,
      isWhitelisted: false,
      hasWhitelistMintStarted: false,
      mintInProgress: false,
      invalidBalance: false,
      isCorrectNetwork: false
    }
  },
  methods: {
    readyToMint() {
      if (this.isMintRunning) {
        return true;
      }
      if (this.hasWhitelistMintStarted && this.isWhitelisted) {
        return true;
      }
      return false;
    },
    getPrice() {
        return parseFloat(this.numToMint*this.price).toFixed( 8 );
    },
    inc() {
      if (this.numToMint < 20) {
         this.numToMint++
      }
    },
    decr() {
      if (this.numToMint > 1) {
        this.numToMint--
      }
    },
    async enableMetaMask() {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log(accounts)
      this.account = accounts[0];

      this.web3 = web3
      this.contract = new web3.eth.Contract(portchieContract.abi, contractAddress)
      this.metaMaskConnected = true
      this.updateBlockchainValues()
      this.contract.methods.isMintRunning().call((err, result) => { 
        console.log(err)
        console.log("isMintRunning: ", result)
        this.isMintRunning = result
      })

      this.contract.methods.isWhitelistMintRunning().call((err, result) => { 
        console.log(err)
        console.log("hasWhitelistMintStarted: ", result)
        this.hasWhitelistMintStarted = result
      })

      this.contract.methods.isWhitelisted(this.account).call((err, result) => { 
        console.log(err)
        console.log("isWhitelisted: ", result)
        this.isWhitelisted = result
      })

      var block = this.web3.eth.getBlock("latest");
      console.log("gasLimit: " + block.gasLimit);

      this.web3.eth.net.getNetworkType().then((result) => {
        console.log("Network: "+result)
        console.log("Expected network: "+ethNetwork)
        this.network = result
        if (this.network != ethNetwork) {
          console.log("Invalid eth network")
        } else {
          this.isCorrectNetwork = true
        }
      });
      this.web3.eth.getGasPrice((error, result) => {
        var gasPriceInGwei = this.web3.utils.fromWei(result, 'gwei');
        this.gasPriceInGwei = parseInt(gasPriceInGwei)
      });

      let options = {
          filter: {
              value: [],
          },     
          fromBlock: 'latest'
      };

      this.contract.events.CreateArt(options)
        .on('data', () => {        
          this.updateBlockchainValues()
        })
        .on('changed', changed => console.log(changed))
        .on('error', err => console.log(err))
        .on('connected', str => console.log(str))
    },
    updateBlockchainValues() {
      this.contract.methods.totalSupply().call((err, result) => { 
        console.log(err)
        this.totalSupply = result
      })
      this.contract.methods.getPrice().call((err, result) => { 
        console.log(err)
        this.price = Web3.utils.fromWei(result, 'ether').toString();
        this.priceWei = result
      })
    },
    metamaskConnected() {
      if (this.account != "") {
        this.metaMaskConnected = true
      }
      return this.metaMaskConnected
    },
    mint() {
      if (!this.isCorrectNetwork) {
        console.log("Incorrect network")
        return
      }
      this.mintInProgress = true
      this.transactionFeedback = false
      this.invalidBalance = false
      this.transactionSubmitted = true
      this.transactionId = ""

      let mintMethod = this.contract.methods.mint
      if (this.hasWhitelistMintStarted) {
        mintMethod = this.contract.methods.mintWhitelist
      }

    web3.eth.getBalance(this.account).then((balance) => {
      console.log("Wallet balance: ",parseInt(balance, 10))
      console.log("Total price: ",web3.utils.toWei(this.getPrice().toString(), 'ether'))
      if (parseInt(balance, 10) < parseInt(web3.utils.toWei(this.getPrice().toString(), 'ether'), 10)) {
        console.log("invalid balance")
        this.mintResponse = "error"
        this.transactionSuccessful = false
        this.invalidBalance = true
        this.transactionFeedback = true
        this.mintInProgress = false
        return;
      }
      web3.eth.getBlock("pending").then((block) => {
        
        console.log("baseFee", Number(block.baseFeePerGas))

        web3.eth.getMaxPriorityFeePerGas().then((maxPriorityFeePerGas) => {
          console.log("getMaxPriorityFeePerGas: ", Number(maxPriorityFeePerGas))

          mintMethod(this.numToMint).estimateGas({
              from: this.account,
              value: this.priceWei*this.numToMint,
          }).then((estimatedGas) => {
            console.log("estimatedGas: ", estimatedGas)
            const max = Number(maxPriorityFeePerGas) + Number(block.baseFeePerGas) - 1; // less than the sum
            mintMethod(this.numToMint).send({
                from: this.account,
                value: this.priceWei*this.numToMint,
                maxPriorityFeePerGas: Number(maxPriorityFeePerGas),
                maxFeePerGas: max,
                gas: Math.trunc(estimatedGas*1.12),
              }, (err, res) => {
                this.transactionFeedback = true
                if (err) {
                  console.log("err: ", err); 
                  this.mintResponse = "error"
                  this.transactionSuccessful = false
                  this.mintInProgress = false
                  return
                }
                this.transactionSuccessful = true
                this.mintInProgress = false
                this.transactionId = res;
                this.mintResponse = "success"
                console.log("res: ",res);
            })

        })

        })
      });
    });
    },
    transactionStatus() {
      if (this.network == "main") return "https://etherscan.io/tx/"+this.transactionId
      if (this.network == "rinkeby") return "https://rinkeby.etherscan.io/tx/"+this.transactionId
    },
  }

// import portchieContract from './Portchie.json'
// const Web3 = require('web3')
// import { createAlchemyWeb3 } from "@alch/alchemy-web3";

// import { BIconTwitter, BIconInstagram, BIconFacebook } from 'bootstrap-icons-vue';

// const alcgemyURL = process.env.VUE_APP_ALCHEMY_URL
// const contractAddress = process.env.VUE_APP_CONTRACT_ADDR
// const contractCodeURL = process.env.VUE_APP_CONTRACT_CODE_URL
// const openSeaCollectionURL = process.env.VUE_APP_OPESEA_COLLECTION_URL
// const openSeaAssetURL = process.env.VUE_APP_OPESEA_ASSET_URL
// const ethNetwork = process.env.VUE_APP_ETH_NETWORK
// const assetFolder = process.env.VUE_APP_ASSET_FOLDER
// const beforeGolive = process.env.VUE_APP_ASSET_BEFORE_GO_LIVE === "true"

// const web3 = createAlchemyWeb3(
//   alcgemyURL,
// );

// export default {
//   name: 'App',
//   components: {
//     BIconTwitter,
//     BIconInstagram,
//     BIconFacebook
//   },
//   mounted() {
//     if (window.ethereum) {
//       console.log("MetaMask detected")
//       this.metaMaskDetected = true
//       if (!beforeGolive) this.enableMetaMask();
//     }
//   },
//   data() {
//     return {
//       totalSupply: 0,
//       price: web3.utils.toWei('0.02', 'ether'),
//       numToMint: 1,
//       account: "",
//       contract: null,
//       metaMaskConnected: false,
//       web3: null,
//       network: "",
//       metaMaskButtonClass: "greenBackground",
//       metaMaskButtonText: "",
//       isMintRunning: false,
//       hasMintStarted: false,
//       hasWhitelistMintStarted: false,
//       isWhitelisted: false,
//       transactionSubmitted: false,
//       transactionFeedback: false,
//       transactionSuccessful: false,
//       gasPriceInGwei: 1,
//       metaMaskDetected: false,
//       isCorrectNetwork: false,
//       showStaticImages: true,
//       showStaticImagesLastId: 0,
//       invalidBalance: false,
//       init: true,
//       lastFoundID: 0,
//       transactionId: "",
//       beforeGolive: beforeGolive
//     }
//   },
//   methods: {
//     readyToMint() {
//       if (this.hasMintStarted) {
//         return true;
//       }
//       if (this.hasWhitelistMintStarted && this.isWhitelisted) {
//         return true;
//       }
//       return false;
//     },
//     getPrice() {
//       if (this.price != 0) {
//         const etherValue = Web3.utils.fromWei(this.price, 'ether');
//         return this.numToMint*etherValue
//       }
//     },
//     async enableMetaMask() {
//       const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
//       this.account = accounts[0];
//       // this.web3 = new Web3(window.ethereum);
//       // this.contract = new this.web3.eth.Contract(portchieContract.abi, contractAddress)
//       this.web3 = web3
//       this.contract = new web3.eth.Contract(portchieContract.abi, contractAddress)
//       this.metaMaskConnected = true
//       this.updateBlockchainValues()
//       this.contract.methods.isMintRunning().call((err, result) => { 
//         console.log(err)
//         this.isMintRunning = result
//       })

//       this.contract.methods.isWhitelistMintRunning().call((err, result) => { 
//         console.log(err)
//         this.hasWhitelistMintStarted = result
//       })

//       this.contract.methods.isWhitelisted(this.account).call((err, result) => { 
//         console.log(err)
//         this.isWhitelisted = result
//       })

//       var block = this.web3.eth.getBlock("latest");
//       console.log("gasLimit: " + block.gasLimit);

//       this.web3.eth.net.getNetworkType().then((result) => {
//         console.log("Network: "+result)
//         this.network = result
//         if (this.network != ethNetwork) {
//           this.metaMaskButtonClass = "btn-warning"
//           this.metaMaskButtonText = result+" (wanted "+ethNetwork+") "
//         } else {
//           this.isCorrectNetwork = true
//         }
//       });
//       this.web3.eth.getGasPrice((error, result) => {
//         var gasPriceInGwei = this.web3.utils.fromWei(result, 'gwei');
//         this.gasPriceInGwei = parseInt(gasPriceInGwei)
//       });

//       let options = {
//           filter: {
//               value: [],
//           },     
//           fromBlock: 'latest'
//       };

//       this.contract.events.CreateArt(options)
//         .on('data', event => {        
//           this.updateBlockchainValues()
//           let data = {
//             id: event.returnValues.id,
//             your: "",
//             assetFolder: assetFolder,
//             openSeaAssetURL: openSeaAssetURL,
//           }


//         let doAlert = (event) => {
//           if (this.account.toLowerCase() == event.returnValues.to.toLowerCase()) {
//             data.your = "Your "

//             let tmp = template.replace(/{([^{}]+)}/g, function(keyExpr, key) {
//                 return data[key] || "";
//             });

//             this.$toast.show(tmp, {
//               type: "success",
//               duration: 15000,
//               maxToasts: 6
//             });
//           } else {
//             let tmp = template.replace(/{([^{}]+)}/g, function(keyExpr, key) {
//                 return data[key] || "";
//             });
//             this.$toast.show(tmp, {
//               type: "info",
//               duration: 3000,
//               maxToasts: 4
//             });
//           }
//         }

//         let cb = async (valid, id, number, event) => {
//           if (!valid) {
//             await sleep(2000);
//             number++
//             if (number < 60) {
//               checkIfLinkExist("https://spaces.portchie-nft.com/"+assetFolder+"/image/"+id+".jpeg", cb, id, number, event)
//             }
//             return
//           }
//           doAlert(event)
//           if (id > this.lastFoundID) {
//             this.lastFoundID = id
//           }
//         }

//         checkIfLinkExist("https://spaces.portchie-nft.com/"+assetFolder+"/image/"+event.returnValues.id+".jpeg", cb, event.returnValues.id, 0, event)

//         })
//         .on('changed', changed => console.log(changed))
//         .on('error', err => console.log(err))
//         .on('connected', str => console.log(str))
//     },
//     updateBlockchainValues() {
//       this.contract.methods.totalSupply().call((err, result) => { 
//         console.log(err)
//         this.totalSupply = result
//         if (this.init) {
//           let checkToId = this.totalSupply-21
//           if (checkToId < 0 ) {
//             checkToId = 0
//           }
//           for (let id = this.totalSupply-1; id >= checkToId; id--) {
//             let cb = async (valid, id) => {
//             if (!valid) {
//               return
//             }
//             if (id > this.lastFoundID) {
//                 this.lastFoundID = id
//                 if (this.lastFoundID >= 8) {
//                   this.showStaticImages = false
//                 }
//             }
//           }

//           checkIfLinkExist("https://spaces.portchie-nft.com/"+assetFolder+"/image/"+id+".jpeg", cb, id, 0)

//           }
//           this.init = false
//         }
//       })
//     },
//     metaMaskkNotConnected() {
//       return this.account == "" 
//     },
//     mint() {
//       this.transactionFeedback = false
//       this.invalidBalance = false
//       this.transactionSubmitted = true
//       this.transactionId = ""

//     let mintMethod = this.contract.methods.mint
//     if (this.hasWhitelistMintStarted) {
//       mintMethod = this.contract.methods.mintWhitelist
//     }

//     web3.eth.getBalance(this.account).then((balance) => {
//       if (balance < web3.utils.toWei(this.getPrice().toString(), 'ether')) {
//         console.log("invalid balance")
//         this.mintResponse = "error"
//         this.transactionSuccessful = false
//         this.invalidBalance = true
//         this.transactionFeedback = true
//         return;
//       }
//       web3.eth.getBlock("pending").then((block) => {
        
//         console.log("baseFee", Number(block.baseFeePerGas))

//         web3.eth.getMaxPriorityFeePerGas().then((maxPriorityFeePerGas) => {
//           console.log("getMaxPriorityFeePerGas: ", Number(maxPriorityFeePerGas))

//           mintMethod(this.numToMint).estimateGas({
//               from: this.account,
//               value: this.price*this.numToMint,
//           }).then((estimatedGas) => {
//             console.log("estimatedGas: ", estimatedGas)
//             const max = Number(maxPriorityFeePerGas) + Number(block.baseFeePerGas) - 1; // less than the sum
//             mintMethod(this.numToMint).send({
//                 from: this.account,
//                 value: this.price*this.numToMint,
//                 maxPriorityFeePerGas: Number(maxPriorityFeePerGas),
//                 maxFeePerGas: max,
//                 gas: Math.trunc(estimatedGas*1.12),
//               }, (err, res) => {
//                 this.transactionFeedback = true
//                 if (err) {
//                   console.log("err: ", err); 
//                   this.mintResponse = "error"
//                   this.transactionSuccessful = false
//                   return
//                 }
//                 this.transactionSuccessful = true
//                 this.transactionId = res;
//                 this.mintResponse = "success"
//                 console.log("res: ",res);
//             })

//         })

//         })
//       });
//     });
//     },
//     contractCodeURL() {
//       return contractCodeURL;
//     },
//     transactionStatus() {
//       if (this.network == "main") return "https://etherscan.io/tx/"+this.transactionId
//       if (this.network == "rinkeby") return "https://rinkeby.etherscan.io/tx/"+this.transactionId
//     },
//     openSeaURL() {
//       return openSeaCollectionURL;
//     },
//     getCryptoCheekImage(index) {
//       if (this.lastFoundID == 0) {
//         return "https://spaces.portchie-nft.com/"+assetFolder+"/image/"+(this.totalSupply-1-index)+".jpeg";
//       }
//       return "https://spaces.portchie-nft.com/"+assetFolder+"/image/"+(this.lastFoundID-index)+".jpeg";
//     },
//     getCryptoCheekOpenSea(index) {
//       if (this.lastFoundID == 0) {
//         return openSeaAssetURL+(this.totalSupply-1-index);
//       }
//       return openSeaAssetURL+(this.lastFoundID-index);
//     }
//   }
}

</script>

<style>
  @import './assets/css/cycling-by-the-riverside-nft.webflow.css';
  @import './assets/css/normalize.css';
  @import './assets/css/webflow.css';

  .boxhead a {
    color: #FFFFFF;
    text-decoration: none;
  }

  .boxhead a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}
.w-nav {
  background: #FFFFFF;
}

.w-nav-menu {
  background: #FFFFFF;
}

.w-nav-button.w--open {
    background-color: #ffffff;
    color: white;
}

</style>
